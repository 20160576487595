import { useState, useEffect } from "react";
import axios from "axios";
import { ROOT_DOMAIN_API } from "../../../Constant";
import "./AdminSetup.css";
import { toast } from 'react-toastify';

function AdminSetup(props) {
    const [admin, setAdmin] = useState([]);

    const { id } = props;

    useEffect(() => {
        const fetchAdmin = async () => {
            try {
                const resp = await axios(ROOT_DOMAIN_API + "/manage/v1/bo_moderator/bo_getAdminById?id=" + id);
                const obj = resp.data;
                if (obj.status && obj.results) {
                    setAdmin(obj.results);
                }
            } catch (error) {
                console.error('Error fetching admin:', error);
            }
        };

        if (id) {
            fetchAdmin();
        }
    }, [id]);

    const updateAdmin = async (e) => {
        e.preventDefault();

        console.log(admin);
        
        try {
            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                }
            };

            const resp = await axios.post(ROOT_DOMAIN_API + "/manage/v1/bo_moderator/bo_updateAdmin", {
                adminId: id,
                admin: admin
            }, config);
            const obj = resp.data;

            if (obj.status) {
                toast.success('Admin updated successfully', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                props.setAdminList(prevList =>
                    prevList.map(item =>
                        item.adm_id === id
                            ? {
                                ...item,
                                adm_page_contact: admin.adm_page_contact
                            }
                            : item
                    )
                );
            } else {
                toast.error('Failed to update the admin', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            console.error('Error updating admin:', error);
            toast.error('Failed to update the admin', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    const updateField = (field, content) => {
        setAdmin(prev => ({
            ...prev,
            [field]: content
        }));

        console.log(admin);
    };

    return (
        <form onSubmit={updateAdmin}>
            <div className={`AdminSetup GridCol1-13 P25 Width100 BoxSizeBorder ${props.childClass}`}>
                <input type="hidden" name="adminId" id="adminId" value={admin.adm_id} />
                <div className="MB10">
                    <div className="FWBold">Name</div>
                    <input
                        type="text"
                        name="adminName"
                        id="adminName"
                        className="ActionInput P5"
                        value={admin.adm_name}
                        disabled
                    />
                </div>
                <div className="MB10">
                    <div className="FWBold">Email</div>
                    <input
                        type="text"
                        name="adminEmail"
                        id="adminEmail"
                        className="ActionInput P5"
                        value={admin.adm_email}
                        disabled
                    />
                </div>
                <div className="MB10">
                    <div className="FWBold">First Name</div>
                    <input
                        type="text"
                        name="adminFirstName"
                        id="adminFirstName"
                        className="ActionInput P5"
                        value={admin.adm_firstname}
                        onChange={(e) => updateField('adm_firstname', e.target.value)}
                    />
                </div>
                <div className="MB10">
                    <div className="FWBold">Last Name</div>
                    <input
                        type="text"
                        name="adminLastName"
                        id="adminLastName"
                        className="ActionInput P5"
                        value={admin.adm_lastname}
                        onChange={(e) => updateField('adm_lastname', e.target.value)}
                    />
                </div>
                <div className="MB10">
                    <div className="FWBold">Job</div>
                    <input
                        type="text"
                        name="adminJob"
                        id="adminJob"
                        className="ActionInput P5"
                        value={admin.adm_job}
                        onChange={(e) => updateField('adm_job', e.target.value)}
                    />
                </div>
                <div className="MB10">
                    <div className="FWBold">Linkedin</div>
                    <input
                        type="text"
                        name="adminLinkedin"
                        id="adminLinkedin"
                        className="ActionInput P5"
                        value={admin.adm_linkedin}
                        onChange={(e) => updateField('adm_linkedin', e.target.value)}
                    />
                </div>
                <div className="MB10">
                    <div className="FWBold">Picture</div>
                    <input
                        type="file"
                        name="adminPicture"
                        id="adminPicture"
                        className="ActionInput P5"
                        onChange={(e) => updateField('adm_picture', e.target.files[0])}
                    />
                    <br/><br/>
                    {admin.adm_picture_link ? <img src={admin.adm_picture_link} width={200} height={200} alt={""} /> : ""}
                </div>
                <div className="MB10 FlexRow">
                    <div className="FWBold MR10">Page Contact ?</div>
                    <label className="switch">
                        <input
                            type="checkbox"
                            name="adminPageContact"
                            id="adminPageContact"
                            className="ActionInput P5"
                            checked={admin.adm_page_contact === 1}
                            onClick={() => updateField('adm_page_contact', admin.adm_page_contact === 1 ? 0 : 1)}
                        />
                        <span className="slider round"></span>
                    </label>
                </div>
                <div className="MB10 FlexRow">
                    <div className="FWBold MR10">Page Contact Email ?</div>
                    <label className="switch">
                        <input
                            type="checkbox"
                            name="adminPageContactEmail"
                            id="adminPageContactEmail"
                            className="ActionInput P5"
                            checked={admin.adm_page_contact_email === 1}
                            onClick={() => updateField('adm_page_contact_email', admin.adm_page_contact_email === 1 ? 0 : 1)}
                        />
                        <span className="slider round"></span>
                    </label>
                </div>

                <div className="popup-footer Gap10 FlexRow JustifyBetween">
                    <button
                        type="submit"
                        className="ActionButton BGreen"
                    >
                        Validate
                    </button>
                    <button
                        className="ActionButton BBlack TWhite"
                        onClick={props.togglePopup}
                    >
                        Close
                    </button>
                </div>
            </div>
        </form>
    );
}

export default AdminSetup;