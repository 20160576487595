import './App.css';
import EditWorkers from './Pages/EditWorkers/EditWorkers';
import Codir from './Pages/Codir/Codir';
import Home from './Pages/Home/Home';
import EditSiteOptions from './Pages/EditSiteOptions/EditSiteOptions';
import HeaderComponent from './Components/HeaderComponent/HeaderComponent';
import { BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
import ManageSites from './Pages/ManageSites/ManageSites';
import Login from './Pages/Login/Login';
import Statistics from './Pages/Statistics/Statistics';
import PendingApproval from './Pages/PendingApproval/PendingApproval';
import AdReporter from './Pages/AdReporter/AdReporter';
import AdReporterPg from './Pages/AdReporterPg/AdReporterPg';
import CreamOfTheCream from './Pages/CreamOfTheCream/CreamOfTheCream';
import Notices from './Pages/Notices/Notices';
import Informations from './Pages/Informations/Informations';
import WebsitesTracker from './Pages/WebsitesTracker/WebsitesTracker';
import Passback from './Pages/Passback/Passback';
import MagicTag from './Pages/MagicTag/MagicTag';
import MagicTagSetup from './Pages/MagicTagSetup/MagicTagSetup';
import PerfPartners from './Pages/PerfPartners/PerfPartners';
import CronExecuted from './Pages/CronExecuted/CronExecuted';
import Tools from './Pages/Tools/Tools'
import Imports from './Pages/Imports/Imports';
import Exports from './Pages/Exports/Exports';
import Translations from './Pages/Translations/Translations';
import TextMail from './Pages/TextMail/TextMail';
import TextMailSetup from './Pages/TextMailSetup/TextMailSetup';
import OverviewPlateform from './Pages/OverviewPlateform/OverviewPlateform';
import BusinessFinder from './Pages/BusinessFinder/BusinessFinder';
import BusinessFinderSetup from './Pages/BusinessFinderSetup/BusinessFinderSetup';
import Guide from './Pages/Guide/Guide';
import GuideSetup from './Pages/GuideSetup/GuideSetup';
import Faq from './Pages/Faq/Faq';
import FaqSetup from './Pages/FaqSetup/FaqSetup';
import FaqCategorySetup from './Pages/FaqCategorySetup/FaqCategorySetup';
import Notification from './Pages/Notification/Notification';
import NotificationSetup from './Pages/NotificationSetup/NotificationSetup';
import RequestformConfig from "./Pages/RequestformConfig/RequestformConfig";
import Admin from './Pages/Admin/Admin';

function App() {
  return (
    <div className="App">
      <HeaderComponent/>
        <BrowserRouter className="LateralNavbar" basename={"/"}>
          <Routes>
              <Route path="/" element={<Home />}/>
              <Route path="/websites" element={<ManageSites />}/>
              <Route path="/login" element={<Login/>}/>
              <Route path="/editWorkers" element={<EditWorkers/>}/>
              <Route path="/codir" element={<Codir/>}/>
              <Route path="/pendingApproval" element={<PendingApproval/>}/>
              <Route path="/adReporter" element={<AdReporter />}/>
              <Route path="/adReporterPg" element={<AdReporterPg />}/>
              <Route path="/options/:id" element={<EditSiteOptions/>}/>
              <Route path="/statistics/:id" element={<Statistics/>}/>
              <Route path="/creamOfTheCream" element={<CreamOfTheCream />}/>
              <Route path="/notices" element={<Notices />}/>
              <Route path="/informations" element={<Informations />}/>
              <Route path="/websitesTracker" element={<WebsitesTracker/>}/>
              <Route path="/passback" element={<Passback/>}/>
              <Route path="/magicTagList" element={<MagicTag/>}/>
              <Route path="/magicTagSetup/:id" element={<MagicTagSetup/>}/>
              <Route path="/perfPartners" element={<PerfPartners/>}/>
              <Route path="/cronExecuted" element={<CronExecuted/>}/>
              <Route path="/tools" element={<Tools/>}/>
              <Route path="/imports" element={<Imports />} />
              <Route path="/exports" element={<Exports />} />
              <Route path="/translations" element={<Translations />} />
              <Route path="/textMailList" element={<TextMail/>}/>
              <Route path="/textMailSetup/:id" element={<TextMailSetup/>}/>
              <Route path="/overviewPlateform" element={<OverviewPlateform/>}/>
              <Route path="/businessFinderList" element={<BusinessFinder/>}/>
              <Route path="/businessFinderSetup/:id" element={<BusinessFinderSetup/>}/>
              <Route path="/guideList" element={<Guide/>}/>
              <Route path="/guideSetup/:id" element={<GuideSetup/>}/>
              <Route path="/faqList" element={<Faq/>}/>
              <Route path="/faqSetup/:id" element={<FaqSetup/>}/>
              <Route path="/faqCategorySetup/:id" element={<FaqCategorySetup/>}/>
              <Route path="/notificationList" element={<Notification/>}/>
              <Route path="/notificationSetup" element={<NotificationSetup/>}/>
              <Route path="/requestformConfig" element={<RequestformConfig/>}/>
              <Route path="/adminList" element={<Admin/>}/>
              <Route path="*" element={<Navigate to="/" />} />
          </Routes>
      </BrowserRouter>
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.2/css/all.min.css"/>
    </div>
  );
}

export default App;
