import './SiteTagsRow.css';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';

function SiteTagsRow(props) {
    return (
        <tr className={"SiteTagsRow VAlignTop"}>
            <td className='P15'>
                {props.tagId}
            </td>
            <td className='P15'>
                {props.tagName}
            </td>
            <td className='P15'>
                {props.tagDate}
            </td>
            <td className='P15 TCenter'>
                {props.tagName !== "MAGIC TAG" &&
                    <button
                        className='ActionButton BRed TWhite'
                        onClick={props.onDeleteTag}
                        data-tooltip-id="delete_tag"
                        data-tooltip-content="Delete Tag">
                        <Tooltip id="delete_tag" />
                        <i class="fa-solid fa-trash TWhite"></i>
                    </button>
                }
            </td>
        </tr>
    );
}

export default SiteTagsRow;
