import { useState, useEffect } from "react";
import axios from "axios";
import { ROOT_DOMAIN_API } from "../../Constant";
import "./RequestformConfigList.css";
import RequestformConfigRow from "./RequestformConfigRow/RequestformConfigRow";
import RequestformConfigSetup from "./RequestformConfigSetup/RequestformConfigSetup";

function RequestformConfigList(props) {
    const [requestformConfigList, setRequestformConfigList] = useState([]);
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const togglePopup = () => {
        setIsPopupOpen(!isPopupOpen);
    };

    useEffect(() => {
        (async () => {
            try {
                const resp = await axios(ROOT_DOMAIN_API + "/manage/v1/bo_moderator/bo_getRequestformConfig");
                const obj = await resp.data;
                if (obj.status && obj.results) {
                    setRequestformConfigList(obj.results);
                }
            } catch (error) {
                console.error(error);
            }
        })();
    }, []);

    return (
        <div className={"requestformConfigList GridCol1-13 ShadowContainer P25 BWhite Width100 BoxSizeBorder " + props.childClass}>
            <div className="TRight MB20">
                <button className="BGreen TWhite ActionButton ML5 NoUnderline" onClick={() => togglePopup(0)}>
                    <i className="fa-solid fa-plus"></i> Add a Requestform Config
                </button>
            </div>
            <table className='Width100'>
                <thead>
                <tr className="BRed TWhite">
                    <th className='P5'>Key</th>
                    <th className='P5'>Value</th>
                    <th className='P5'>Actions</th>
                </tr>
                </thead>
                <tbody>
                {requestformConfigList.map((row) => (
                    <RequestformConfigRow
                        row={row}
                        setRequestformConfigList={setRequestformConfigList}
                    />
                ))}
                </tbody>
            </table>

            {isPopupOpen && (
                <div>
                    <div className='overlay'></div>
                    <div className="popup">
                        <div className='popup-content-wrapper'>
                            <RequestformConfigSetup
                                id={""}
                                togglePopup={togglePopup}
                                setRequestformConfigList={setRequestformConfigList}
                                requestformConfigList={requestformConfigList}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default RequestformConfigList;