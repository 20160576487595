import './AdReportListPg.css';
import { useState, useEffect } from "react";
import axios from "axios";
import AdReportListPgRow from './AdReportListPgRow/AdReportListPgRow';
import { ROOT_DOMAIN_API } from '../../Constant';
axios.defaults.withCredentials = true;


function AdReportListPg(props) {
    const [adReportList, setAdReportList] = useState([]);
    const [showNonProcessed, setShowNonProcessed] = useState(true);

    useEffect(() => {
        loadReportList();
    }, []);

    const loadReportList = async () => {
        try {
            const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/ad_reporter_3rd_party/bo_adReportListPg");
            const obj = await resp.data;
            if (obj.status && obj.results) {
                if (obj.status && obj.results) {
                    setAdReportList(obj.results);
                }
            }
        } catch (error) {
            console.error(error);
        }
    }

    const toggleShowNonProcessed = () => {
        setShowNonProcessed(!showNonProcessed);
    };

    const filteredAdReportList = showNonProcessed
        ? adReportList.filter(row => row.ad_report_state !== 1)
        : adReportList;

    return (
        <div className={"AdReportList GridCol1-13 ShadowContainer P25 BWhite Width100 BoxSizeBorder " + props.childClass}>
            <button className="ActionButton BBlue TWhite MB10" onClick={toggleShowNonProcessed}><i className="fa-solid fa-eye" ></i>
                {showNonProcessed ? ' Display All Ad Reports' : ' Display Only Unprocessed Ad Report'}
            </button>
            <button className='ML5 MB10 BGreen TWhite ActionButton' onClick={loadReportList} ><i className="fa-solid fa-refresh" ></i> Reload Report List</button>

            <table className='Width100'>
                <thead>
                    <tr className="BRed TWhite">
                        <th className='P5'>Url</th>
                        <th className='P5'>Ad Report Details</th>
                        <th className='P5'>Reason / Message</th>
                        <th className='P5'>Actions</th>
                        {showNonProcessed && <th className='P5'>Status</th>}
                    </tr>
                </thead>
                <tbody>
                    {filteredAdReportList.map((row) => {
                        return <AdReportListPgRow key={row.ad_report_id}
                            adReportId={row.ad_report_id}
                            formatId={row.ad_report_format_id}
                            reportReason={row.ad_report_reason}
                            reportMessage={row.ad_report_message}
                            iframeContent={row.ad_report_iframe_content}
                            reportBidder={row.ad_report_bidder}
                            reportAdId={row.ad_report_ad_id}
                            reportCreativeId={row.ad_report_creative_id}
                            reportAd={row.ad_report_ad}
                            reportDate={row.ad_report_date}
                            reportState={row.ad_report_state}
                            showNonProcessed={showNonProcessed} />
                    })}
                </tbody>
            </table>
        </div>
    );
}

export default AdReportListPg;
