import { useEffect, useState } from 'react';
import './WorkersRow.css';
import axios from 'axios';
import { ROOT_DOMAIN_API } from '../../../Constant';

function WorkersRow(props) {
  const [rolesList, setRolesList] = useState([]);
  const [selectedRole, setSelectedRole] = useState("-1");
  // const [adminRoles, setAdminRoles] = useState([]);

  const handleDeleteRole = function (roleId) {
    (async () => {
      try {
        let data = {
          params: {
            adminId: props.admId,
            roleId: roleId
          }
        }
        const resp = await axios.delete(ROOT_DOMAIN_API + "/manage/v1/bo_admin/rolesList", data);
        const obj = await resp.data;
        if (obj.status && obj.results) {
          props.hotReload()
        };
      } catch (error) {
        console.error(error);
      }
    })();
  }

  const handleAddRole = function () {
    if (selectedRole === "-1") {
      return;
    }
    if (!props.admId) {
      return;
    }

    (async () => {
      try {
        let data = {
          adminId: props.admId,
          roleId: selectedRole
        }
        const resp = await axios.put(ROOT_DOMAIN_API + "/manage/v1/bo_admin/rolesList", data);
        const obj = await resp.data;
        if (obj.status && obj.results) {
          props.hotReload()
        };
      } catch (error) {
        console.error(error);
      }
    })();
  }

  useEffect(() => {
    setRolesList(props.rolesList);
  }, [props.rolesList])

  return (
    <div className="WorkersRow GridCol3 ShadowContainer P25 BVerySoftPurple FlexCol AlignCenter JustifyCenter BoxSizeBorder">
      <img className="Width50 BorderRad3" src={props.picture ? ("https://cdn.themoneytizer.fr/mod_new_home/img/contacts/" + props.picture) : "https://api.dicebear.com/5.x/big-ears-neutral/svg?seed=No"} alt='Contact Person' />
      <p>{props.name}</p>
      <div className="FlexCol Width100">
        <label htmlFor={"addRole-" + props.admId}>Add role</label>
        <div className="FlexRow">
          <select onChange={(e) => setSelectedRole(e.target.value)} className='ActionSelect'>
            <option value="-1">Select a role</option>
            {rolesList.map((row, key) => {
              return <option key={key} value={row.id}>{row.name}</option>
            })}
          </select>
          <button onClick={handleAddRole} className="ActionButton BRed P5 ML10">
            <i className="fa-solid fa-plus MR5"></i>Add
          </button>
        </div>
      </div>
      <div className="Width100 P5 MT10">
        <div className='ShadowContainer BWhite P5 FlexRow WrapContent Width100 Gap5 JustifyCenter'>
          {props.adminRoles.map((row, key) => {
            return (<div className="BRed P5 BorderRad3 TWhite LightText" key={key}>
              {row.name}
              <button onClick={() => handleDeleteRole(row.role_id)} className="LightButton TWhite ML5 BRed">
                <i className="fa-solid fa-xmark"></i>
              </button>
            </div>)
          })}
          {props.adminRoles.length === 0 ? <div className="BPurplePink P5 BorderRad3 TWhite LightText">No Role</div> : ''}
        </div>
      </div>
    </div>
  );
}

export default WorkersRow;
