import { useState, useEffect } from "react";
import axios from "axios";
import { ROOT_DOMAIN_API } from "../../Constant";
import "./AdminList.css";
import AdminRow from "./AdminRow/AdminRow";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

function AdminList(props) {
    const [adminList, setAdminList] = useState([]);

    useEffect(() => {
        (async () => {
            try {
                const resp = await axios(ROOT_DOMAIN_API + "/manage/v1/bo_moderator/bo_getAdmin");
                const obj = await resp.data;
                if (obj.status && obj.results) {
                    setAdminList(obj.results);
                }
            } catch (error) {
                console.error(error);
            }
        })();
    }, []);

    const updateAdminStatus = (id, status) => {
        (async () => {
            const resp = await axios.post(ROOT_DOMAIN_API + '/manage/v1/bo_moderator/bo_updateAdminStatus',
                { id: id, status: status });
            const obj = await resp.data;
            if (obj.status) {
                setAdminList(prevList =>
                    prevList.map(item =>
                        item.adm_id === id
                            ? {
                                ...item,
                                adm_access: status
                            }
                            : item
                    )
                );
            }
        })();
    }

    const updateAdminPageContact = (id, status) => {
        (async () => {
            const resp = await axios.post(ROOT_DOMAIN_API + '/manage/v1/bo_moderator/bo_updateAdminPageContact',
                { id: id, status: status });
            const obj = await resp.data;
            if (obj.status) {
                setAdminList(prevList =>
                    prevList.map(item =>
                        item.adm_id === id
                            ? {
                                ...item,
                                adm_page_contact: status
                            }
                            : item
                    )
                );
            }
        })();
    }

    const onDragEnd = (result) => {
        if (!result.destination) return;

        console.log(result.source.index);
        console.log(result.destination.index);

        const items = Array.from(adminList);

        console.log(items[result.source.index]);
        console.log(items[result.destination.index]);

        const updateAdminId = items[result.source.index].adm_id;
        const updateAdminRank = items[result.destination.index].adm_rank;

        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setAdminList(items);

        (async () => {
            await axios.post(ROOT_DOMAIN_API + '/manage/v1/bo_moderator/bo_updateAdminRank',
                {
                    id: updateAdminId,
                    rank: updateAdminRank
                }
            );
        })();
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="adminList">
                {(provided) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className={"AdminList GridCol1-13 ShadowContainer P25 BWhite Width100 BoxSizeBorder " + props.childClass}
                    >
                        <table className='Width100'>
                            <thead>
                            <tr className="BRed TWhite">
                                <th className='P5'>ID</th>
                                <th className='P5'>Name</th>
                                <th className='P5'>Email</th>
                                <th className='P5'>Page Contact ?</th>
                                <th className='P5'>Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            {adminList.map((row, index) => (
                                <Draggable key={row.adm_id} draggableId={row.adm_id.toString()} index={index}>
                                    {(provided) => (
                                            <AdminRow
                                                id={row.adm_id}
                                                name={row.adm_name}
                                                email={row.adm_email}
                                                pageContact={row.adm_page_contact}
                                                access={row.adm_access}
                                                updateAdminStatus={updateAdminStatus}
                                                updateAdminPageContact={updateAdminPageContact}
                                                provided={provided}
                                                setAdminList={setAdminList}
                                            />
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                            </tbody>
                        </table>
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
}

export default AdminList;