import './AdminRow.css';
import { useState } from 'react';
import AdminSetup from '../AdminSetup/AdminSetup';

function AdminRow(props) {
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const togglePopup = () => {
        setIsPopupOpen(!isPopupOpen);
    };

    return (
        <>
            <tr className="AdminRow" ref={props.provided.innerRef}
                {...props.provided.draggableProps}
                {...props.provided.dragHandleProps}>
                <td className='P5'>
                    <i className="fa-solid fa-arrows-up-down-left-right MR15" style={{ fontSize: '20px' }}></i>
                    {props.id}
                </td>
                <td className='P5'>
                    {props.name}
                </td>
                <td className='P5'>
                    {props.email}
                </td>
                <td className='P5'>
                    <label className="switch">
                        <input type="checkbox"
                            onClick={() => props.updateAdminPageContact(props.id, props.pageContact === 1 ? 0 : 1)}
                            checked={props.pageContact === 1}
                        />
                        <span className="slider round"></span>
                    </label>
                </td>
                <td className='P5 FlexRow JustifyCenter Gap10'>
                    <button 
                        className="BOrange TWhite ActionButton ML5"
                        onClick={togglePopup}
                    >
                        <i className="fa-solid fa-pen"></i> Edit
                    </button>
                    <label className="switch">
                        <input type="checkbox"
                            onClick={() => props.updateAdminStatus(props.id, props.access === 1 ? 0 : 1)}
                            checked={props.access === 1}
                        />
                        <span className="slider round"></span>
                    </label>
                </td>
            </tr>

            {isPopupOpen && (
                <div>
                    <div className='overlay'></div>
                    <div className="popup">
                        <div className='popup-content-wrapper'>
                            <AdminSetup
                                id={props.id}
                                onSuccess={togglePopup}
                                togglePopup={togglePopup}
                                setAdminList={props.setAdminList}
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default AdminRow;