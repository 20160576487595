import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { ROOT_DOMAIN_API } from "../../Constant";
import 'react-toastify/dist/ReactToastify.css';
import SplashLoadComponent from "../SplashLoadComponent/SplashLoadComponent";
import ReactPaginate from "react-paginate";
import './PerfSsp.css';

function PerfSsp(props) {
    const [perfSsp, setPerfSsp] = useState([]);
    const [perfSspDefault, setPerfSspDefault] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(50); // Default to 50 items per page
    const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
    const [sspRevenuesFormat, setSspRevenuesFormat] = useState([]);
    const [sspRevenuesPartner, setSspRevenuesPartner] = useState([]);
    const [sspRevenuesFormatSelected, setSspRevenuesFormatSelected] = useState("0");
    const [sspRevenuesPartnerSelected, setSspRevenuesPartnerSelected] = useState("0");

    const handlePageClick = (data) => {
        setCurrentPage(data.selected);
    };

    const handleItemsPerPageChange = (event) => {
        setItemsPerPage(Number(event.target.value));
        setCurrentPage(0); // Reset to the first page
    };

    const fetchPerfSspData = useCallback(async () => {
        if (!props.siteID) return;
        setIsLoading(true);
        try {
            const response = await axios.get(`${ROOT_DOMAIN_API}/manage/v1/bo_user/bo_getPerfSsp`, {
                params: {
                    site_id: props.siteID,
                    date_from: props.sDate,
                    date_to: props.eDate,
                }
            });
            const obj = response.data;
            if (obj.status && obj.results) {
                setPerfSsp(obj.results);
                setPerfSspDefault(obj.results);

                const formatsList = [];
                const partnersList = [];

                // eslint-disable-next-line
                obj.results.map((row) => {
                    const formatsListExists = formatsList.findIndex(element => element === row.form_name) > -1;
                    if (!formatsListExists) {
                        formatsList.push(row.form_name);
                    }

                    const partnersListExists = partnersList.findIndex(element => element === row.perf_origin) > -1;
                    if (!partnersListExists) {
                        partnersList.push(row.perf_origin);
                    }
                });

                setSspRevenuesFormat(formatsList);
                setSspRevenuesPartner(partnersList);
            }
        } catch (error) {
            console.error("Error fetching perf SSP:", error);
        } finally {
            setIsLoading(false);
        }
    }, [props.siteID, props.sDate, props.eDate]);

    useEffect(() => {
        fetchPerfSspData();
    }, [fetchPerfSspData]);

    const sortData = (data, config) => {
        const sortedData = [...data];
        if (config.key) {
            sortedData.sort((a, b) => {
                const aValue = a[config.key];
                const bValue = b[config.key];

                if (typeof aValue === 'number' && typeof bValue === 'number') {
                    return config.direction === 'ascending' ? aValue - bValue : bValue - aValue;
                }

                if (typeof aValue === 'string' && typeof bValue === 'string') {
                    return config.direction === 'ascending'
                        ? aValue.localeCompare(bValue)
                        : bValue.localeCompare(aValue);
                }

                if (!isNaN(aValue) && !isNaN(bValue)) {
                    return config.direction === 'ascending' ? aValue - bValue : bValue - aValue;
                }

                return 0;
            });
        }
        return sortedData;
    };

    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const getSortIcon = (key) => {
        if (sortConfig.key === key) {
            return sortConfig.direction === 'ascending' ? 'fa-sort-up' : 'fa-sort-down';
        }
        return 'fa-sort';
    };

    const sortedData = sortData(perfSsp, sortConfig);
    const pageCount = Math.ceil(sortedData.length / itemsPerPage);
    const offset = currentPage * itemsPerPage;
    const currentPageData = sortedData.slice(offset, offset + itemsPerPage);

    const handleSelectChangeSspRevenuesFormat = (e) => {
        setPerfSsp(perfSspDefault);
        setSspRevenuesFormatSelected(e.target.value);

        if(sspRevenuesPartnerSelected !== "0"){
            setPerfSsp(prevList =>
                prevList.filter(item => item.perf_origin === sspRevenuesPartnerSelected)
            );
        }

        if(e.target.value === "0"){
            return false;
        }

        setPerfSsp(prevList =>
            prevList.filter(item => item.form_name === e.target.value)
        );
    };

    const handleSelectChangeSspRevenuesPartner = (e) => {
        setPerfSsp(perfSspDefault);
        setSspRevenuesPartnerSelected(e.target.value);

        if(sspRevenuesFormatSelected !== "0"){
            setPerfSsp(prevList =>
                prevList.filter(item => item.form_name === sspRevenuesFormatSelected)
            );
        }

        if(e.target.value === "0"){
            return false;
        }

        setPerfSsp(prevList =>
            prevList.filter(item => item.perf_origin === e.target.value)
        );
    };

    return (
        <div className={"PerfSsp GridCol1-13 ShadowContainer P25 BWhite Width100 BoxSizeBorder " + props.childClass}>
            <div className="FlexRow">
                <h2 className="MT0 MR20">SSP Revenues</h2>
                <div className="FlexCol MR20">
                    <select onChange={handleSelectChangeSspRevenuesFormat} id="ssp_revenues_format"
                            className="ActionSelect P5">
                        {sspRevenuesFormat.length < 1 ?
                            <option value="-1">No format available for this period.</option> :
                            <option value="0">Format name</option>}
                        {sspRevenuesFormat.map((row) => (
                            <option value={row}>{row}</option>
                        ))}
                    </select>
                </div>
                <div className="FlexCol">
                    <select onChange={handleSelectChangeSspRevenuesPartner} id="ssp_revenues_partner"
                            className="ActionSelect P5">
                        {sspRevenuesPartner.length < 1 ?
                            <option value="-1">No partner available for this period.</option> :
                            <option value="0">Partner name</option>}
                        {sspRevenuesPartner.map((row) => (
                            <option value={row}>{row}</option>
                        ))}
                    </select>
                </div>
                <div className="pagination-controls" style={{marginLeft: "auto"}}>
                    <label htmlFor="itemsPerPage" className="SmallFont">Items per page </label>
                    <select id="itemsPerPage" value={itemsPerPage} onChange={handleItemsPerPageChange}>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                        <option value={250}>250</option>
                        <option value={500}>500</option>
                    </select>
                </div>
            </div>

            {isLoading ? (
                <SplashLoadComponent/>
            ) : (
                <>

                    <table className='Width100'>
                        <thead>
                        <tr className="BRed TWhite">
                            <th className="P5 CursorPointer" onClick={() => requestSort('form_name')}>
                                Format <i className={`fa ${getSortIcon('form_name')}`}></i>
                            </th>
                            <th className='P5 CursorPointer' onClick={() => requestSort('perf_views')}>
                                Impressions <i className={`fa ${getSortIcon('perf_views')}`}></i>
                                </th>
                                <th className="P5 CursorPointer" onClick={() => requestSort('perf_CPM_private')}>
                                    CPM The Moneytizer <i className={`fa ${getSortIcon('perf_CPM_private')}`}></i>
                                </th>
                                <th className="P5 CursorPointer" onClick={() => requestSort('perf_CPM_editeur')}>
                                    CPM Publisher <i className={`fa ${getSortIcon('perf_CPM_editeur')}`}></i>
                                </th>
                                <th className="P5 CursorPointer" onClick={() => requestSort('perf_revenues')}>
                                    Publisher revenues <i className={`fa ${getSortIcon('perf_revenues')}`}></i>
                                </th>
                                <th className="P5 CursorPointer" onClick={() => requestSort('perf_revenues_private')}>
                                    Revenues private <i className={`fa ${getSortIcon('perf_revenues_private')}`}></i>
                                </th>
                                <th className="P5 CursorPointer" onClick={() => requestSort('perf_origin')}>
                                    Origin <i className={`fa ${getSortIcon('perf_origin')}`}></i>
                                </th>
                                <th className="P5 CursorPointer" onClick={() => requestSort('perf_date')}>
                                    Date <i className={`fa ${getSortIcon('perf_date')}`}></i>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentPageData.map((site, key) => (
                                <tr key={key}>
                                    <td className='P15'>{site.form_name}</td>
                                    <td className='P15'>{site.perf_views}</td>
                                    <td className='P15'>{((site.perf_revenues_private * site.perf_views) / 1000).toFixed(2)}</td>
                                    <td className='P15'>{((site.perf_revenues * site.perf_views) / 1000).toFixed(2)}</td>
                                    <td className='P15'>{site.perf_revenues}</td>
                                    <td className='P15'>{site.perf_revenues_private}</td>
                                    <td className='P15'>{site.perf_origin}</td>
                                    <td className='P15'>{site.perf_date}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <ReactPaginate
                        previousLabel={"< previous"}
                        nextLabel={"next >"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={"Pagination"}
                        activeClassName={"active"}
                    />
                </>
            )}
        </div>
    );
}

export default PerfSsp;
