import './RequestformConfigRow.css';
import { useState } from 'react';
import RequestformConfigSetup from '../RequestformConfigSetup/RequestformConfigSetup';
import axios from "axios";
import {ROOT_DOMAIN_API} from "../../../Constant";

function RequestformConfigRow(props) {
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const togglePopup = () => {
        setIsPopupOpen(!isPopupOpen);
    };

    const deleteRequestformConfig = (key) => {
        if (window.confirm("Are you sure you want to delete the config '" + key + "' ?")) {
            (async () => {
                try {
                    const resp = await axios.post(ROOT_DOMAIN_API + "/manage/v1/bo_moderator/bo_deleteRequestformConfig",
                        {'key':key});
                    const obj = await resp.data;
                    if (obj.status && obj.results) {
                        props.setRequestformConfigList(prevList =>
                            prevList.filter(item => item.key !== key)
                        );
                    }
                } catch (error) {
                    console.error(error);
                }
            })();
        }
    }

    return (
        <>
            <tr className="RequestformConfigRow">
                <td className='P5'>{props.row.key}</td>
                <td className='P5'>{props.row.value}</td>
                <td className='P5 FlexRow JustifyCenter Gap10'>
                    <button
                        className="BOrange TWhite ActionButton ML5"
                        onClick={togglePopup}
                    >
                        <i className="fa-solid fa-pen"></i> Edit
                    </button>
                    <button
                        className="BRed TWhite ActionButton ML5"
                        onClick={(e) => deleteRequestformConfig(props.row.key)}
                    >
                        <i className="fa-solid fa-trash"></i> Delete
                    </button>
                </td>
            </tr>

            {isPopupOpen && (
                <div>
                    <div className='overlay'></div>
                    <div className="popup">
                        <div className='popup-content-wrapper'>
                        <RequestformConfigSetup
                                id={props.row.key}
                                onSuccess={togglePopup}
                                togglePopup={togglePopup}
                                setRequestformConfigList={props.setRequestformConfigList}
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default RequestformConfigRow;