import './PutAsideSites.css';
import { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import PutAsideSitesRow from './PutAsideSitesRow/PutAsideSitesRow';
import ReactPaginate from 'react-paginate';
import { ROOT_DOMAIN_API } from '../../Constant';

function PutAsideSites(props) {
    const [putAsideSitesList, setPutAsideSitesList] = useState([]);
    const [themesList, setThemesList] = useState([]);
    const [siteCountryList, setSiteCountryList] = useState([]);
    const [raisonPutAsideList, setRaisonPutAsideList] = useState([]);
    const [reasonRefuseList, setReasonRefuseList] = useState([]);
    const [query, setQuery] = useState("");
    const [queryDisplay, setQueryDisplay] = useState("");
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const limit = 6;
    const debounceTimeout = useRef(null);

    const loadPutAsideSites = useCallback(async () => {
        try {
            const payload = {
                params: {
                    q: query || "",
                    page: currentPage,
                    limit: limit,
                }
            };
            const resp = await axios.get(`${ROOT_DOMAIN_API}/manage/v1/bo_user/bo_putAsideSites`, payload);
            const obj = await resp.data;
            if (obj.status && obj.results) {
                setPutAsideSitesList(obj.results.payload);
                setPageCount(Math.ceil(obj.results.amount / limit));
            }
        } catch (error) {
            console.error(error);
        }
    }, [query, currentPage]);

    useEffect(() => {
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }

        debounceTimeout.current = setTimeout(() => {
            if (queryDisplay.length >= 3 || queryDisplay.length === 0) {
                setQuery(queryDisplay);
                setCurrentPage(0); 
            }
        }, 300);
    }, [queryDisplay]);

    useEffect(() => {
        loadPutAsideSites();
    }, [query, currentPage, loadPutAsideSites]);

    const handlePageClick = (e) => {
        setCurrentPage(e.selected);
    }

    useEffect(() => {
        async function fetchData() {
            if (!themesList.length && !siteCountryList.length && !raisonPutAsideList.length && !reasonRefuseList.length) {
                try {
                    const themesResponse = await axios.get(ROOT_DOMAIN_API + "/manage/v1/bo_user/themesList");
                    const siteCountryResponse = await axios.get(ROOT_DOMAIN_API + "/manage/v1/bo_user/bo_siteCountryList");
                    const raisonPutAsideResponse = await axios.get(ROOT_DOMAIN_API + "/manage/v1/bo_user/bo_getRaisonPutAside");
                    const reasonRefuseResponse = await axios.get(ROOT_DOMAIN_API + "/manage/v1/bo_user/bo_getReasonRefuse");
                    if (themesResponse.data.status && siteCountryResponse.data.status && raisonPutAsideResponse.data.status && reasonRefuseResponse.data.status) {
                        setThemesList(themesResponse.data.results);
                        setSiteCountryList(siteCountryResponse.data.results);
                        setRaisonPutAsideList(raisonPutAsideResponse.data.results);
                        setReasonRefuseList(reasonRefuseResponse.data.results);
                    }
                } catch (error) {
                    console.error(error);
                }
            }
        }
        fetchData();
    }, [themesList, siteCountryList, raisonPutAsideList, reasonRefuseList]);

    const handleSiteAction = (siteId) => {
        setPutAsideSitesList(prevSites => prevSites.filter(site => site.site_id !== siteId));
    };

    return (
        <div className={"PutAsideSites GridCol1-13 ShadowContainer P25 BWhite Width100 BoxSizeBorder " + props.childClass}>
            <input
                type="text"
                className="ActionInput P10 Width35 MB10"
                onChange={(e) => setQueryDisplay(e.target.value)}
                placeholder='Search for a website...'
            />
            <table className='Width100'>
                <thead>
                    <tr className="BRed TWhite">
                        <th className='P5'>Details</th>
                        <th className='P5'>Url</th>
                        <th className='P5'>Put Aside Reason</th>
                        <th className='P5'>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {putAsideSitesList.map((row, key) => (
                        <PutAsideSitesRow
                            key={row.site_id}
                            userPremium={row.user_premium}
                            userMailValid={row.user_mail_valid}
                            nbSiteActif={row.nb_site_actif}
                            nbSiteAccepted={row.nb_site_accepted}
                            cacIso2={row.cac_iso2}
                            userId={row.user_id}
                            siteChild={row.site_child}
                            siteId={row.site_id}
                            siteUrl={row.site_url}
                            adminRoles={row.roles}
                            picture={row.adm_picture}
                            name={row.adm_name}
                            admId={row.adm_id}
                            sponsorMail={row.user_parrain_email}
                            userMail={row.user_mail}
                            siteDate={row.site_date}
                            themesList={themesList}
                            siteThemeId={row.site_theme}
                            userFirstname={row.user_firstname}
                            userLastname={row.user_lastname}
                            siteCountryList={siteCountryList}
                            raisonPutAsideList={raisonPutAsideList}
                            reasonRefuseList={reasonRefuseList}
                            putAsideReason={row.raison_mis_de_cote_libelle}
                            doublonsAccepted={row.doublons_accepted}
                            doublonsRaisonRefus={row.doublons_raison_refus}
                            doublonsFraud={row.doublons_fraud}
                            userModeration={row.user_moderation}
                            userFraud={row.user_fraud}
                            onSiteAction={handleSiteAction}
                        />
                    ))}
                </tbody>
            </table>
            <ReactPaginate
                className='Pagination'
                breakLabel="..."
                nextLabel="next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel="< previous"
                renderOnZeroPageCount={null}
            />
        </div>
    );
}

export default PutAsideSites;
